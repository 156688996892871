<template>
	<div>
		<ParameterComponent></ParameterComponent>
	</div>
</template>

<script>
import ParameterComponent from '../components/parameters/Index';

export default {
	name: 'Parametres',
	components: {
		ParameterComponent,
	},
};
</script>
